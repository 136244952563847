import { render, staticRenderFns } from "./PageReviewSlider.vue?vue&type=template&id=588cb385&"
import script from "./PageReviewSlider.vue?vue&type=script&lang=js&"
export * from "./PageReviewSlider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReviewBlock: require('/home/forge/www.champagne-booking.com/components/ReviewBlock.vue').default,AppSwiperItem: require('/home/forge/www.champagne-booking.com/components/AppSwiperItem.vue').default,AppSwiper: require('/home/forge/www.champagne-booking.com/components/AppSwiper.vue').default})
