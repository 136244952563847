
export default {
  name: 'ReviewBlock',
  props: {
    name: {
      type: String,
    },
    description: {
      type: String,
    },
    image: {
      type: String,
      default: null,
    },
    stars: {
      type: Number,
      default: 5,
    },
    maisonLink: {
      type: String,
    },
    maison: {
      type: String,
    },
    activityLink: {
      type: String,
    },
    activity: {
      type: String,
    },
  },
};
