
export default {
  name: 'AppStars',
  props: {
    average: {
      type: Number,
    },
    hideLabel: {
      type: Boolean,
    },
    count: {
      type: Number,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
};
