
import page from '@/mixins/page';

export default {
  name: 'PageRegions',
  mixins: [page],
  data() {
    return {
      options: {
        threshold: 15,
        rewind: true,
        spaceBetween: 20,
        slidesPerView: 1,
        slidesPerGroup: 1,
        breakpoints: {
          992: {
            slidesPerView: 3.2,
            slidesPerGroup: 3,
          },
          1200: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
      },
    };
  },
};
