
import 'swiper/swiper.min.css';

export default {
  name: 'AppSwiper',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    withNavigation: {
      type: Boolean,
      default: false,
    },
    withPagination: {
      type: Boolean,
      default: false,
    },
    withScrollbar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiper: null,
      className: `swiper-${this._uid}`,
      hidden: true,
    };
  },
  mounted() {
    this.swiper = new this.$swiper(`.${this.className}`, {
      ...this.swiperOptions,
    });

    this.swiper.on('sliderFirstMove', () => {
      this.$refs?.swiper?.classList?.add('block-link');
    });

    this.swiper.on('touchEnd', () => {
      this.$refs?.swiper?.classList?.remove('block-link');
    });

    this.hidden = false;
  },
  destroyed() {
    this.swiper.offAny('sliderFirstMove');
    this.swiper.offAny('touchEnd');
  },
  computed: {
    swiperOptions() {
      let navigation = {};
      let pagination = {};
      let scrollbar = {};

      if (this.withNavigation) {
        navigation = {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        };
      }

      if (this.withPagination) {
        pagination = {
          el: '.swiper-pagination',
          clickable: true,
        };
      }

      if (this.withScrollbar) {
        scrollbar = {
          el: '.swiper-scrollbar',
          draggable: true,
        };
      }

      return {
        navigation,
        pagination,
        scrollbar,
        ...this.options,
      };
    },
    css() {
      return {
        '--padding-bottom': this.withScrollbar ? '0.75rem' : 0,
      };
    },
    swiperRef() {
      return this.swiper;
    },
  },
  watch: {
    swiper: {
      handler(swiper) {
        if (
          Object.keys(this.swiperOptions).includes('autoplay') &&
          !!this.swiperOptions.autoplay
        ) {
          setTimeout(() => {
            swiper?.autoplay?.start();
          }, 500);
        }
      },
    },
  },
};
